import React from 'react';
import { Box, Button, Container, Heading, Text, VStack, HStack, Link, Image, Icon } from '@chakra-ui/react';
import { FaTelegram, FaWhatsapp, FaEnvelope, FaYandex, FaStore } from 'react-icons/fa';

function App() {
  return (
    <Box bg="black" minH="100vh" color="white">
      
      {/* Фиксированный Header */}
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        bg="black"
        zIndex="100"
        px={8}
        py={4}
        boxShadow="0px 4px 10px rgba(0, 255, 255, 0.2)"
      >
        <HStack justify="space-between">
          <Heading size="lg" color="red.500">
            <Image 
            src="https://ucoms.ru/ucoms_logo.png"
            height= "150px"
            filter= "drop-shadow(0px 0px 10px rgba(128, 0, 255, 0.5))"
            />
                

          </Heading>
          <HStack spacing={4}>
            <Link href="https://t.me/ucoms" isExternal>
              <Button colorScheme="blue" leftIcon={<Icon as={FaTelegram} />} borderRadius="full">
                Telegram
              </Button>
            </Link>
            <Link href="https://wa.me/79998015525" isExternal>
              <Button colorScheme="green" leftIcon={<Icon as={FaWhatsapp} />} borderRadius="full">
                WhatsApp
              </Button>
            </Link>
            <Link href="mailto:help@ucoms.ru" isExternal>
              <Button colorScheme="red" leftIcon={<Icon as={FaEnvelope} />} borderRadius="full">
                Email
              </Button>
            </Link>
          </HStack>
        </HStack>
      </Box>

      {/* Основной контейнер */}
      <Container maxW="container.xl" pt="250px">
        
        {/* Основной блок (заголовок + маркетплейсы) */}
        <HStack spacing={12} align="center" justify="center">
          
          {/* Левая часть с текстом и кнопками */}
          <VStack align="start" spacing={4} maxW="500px">
            <Heading size="2xl" fontWeight="bold">
              КОМПЬЮТЕРЫ <br /> ДЛЯ РАБОТЫ <br /> И ОТДЫХА
            </Heading>
            <Text fontSize="lg" color="gray.400">
              Собери свой идеальный ПК!
            </Text>
            <HStack spacing={4} width="100%">
              <Link href="https://market.yandex.ru/business--iukoms/4832020" isExternal width="100%">
                <Button 
                  colorScheme="yellow" 
                  size="lg" 
                  width="100%" 
                  leftIcon={<Icon as={FaYandex} />}
                  borderRadius="full"
                >
                  Яндекс Маркет
                </Button>
              </Link>
              <Link href="https://www.ozon.ru/seller/yukoms-kompyuternyy-magazin-394200/elektronika-15500/" isExternal width="100%">
                <Button 
                  colorScheme="blue" 
                  size="lg" 
                  width="100%" 
                  leftIcon={<Icon as={FaStore} />}
                  borderRadius="full"
                >
                  Ozon
                </Button>
              </Link>
            </HStack>
          </VStack>

          {/* Правая часть с картинкой */}
          <Image
            src="https://ucoms.ru/PC_UCOMS.png"
            alt="Gaming PC"
            maxW="450px"
            borderRadius="lg"
            boxShadow="0px 0px 20px rgba(0, 255, 255, 0.5)"
          />
        </HStack>
      </Container>
    </Box>
  );
}

export default App;
